import styled from "@emotion/styled";
import React from "react";
import theme from "../theme";
import { mediaQuery } from "../theme/breakpoints";

const StyledPageTitle = styled.div<{ padding: string }>`
  margin-top: ${theme.space[1]};
  margin-bottom: ${theme.space[1]};

  h3 {
    color: ${theme.colors.black};
    margin-block-start: 0;
    margin-block-end: 0;
    font-size: ${theme.fontSizes.xl};

    ${mediaQuery(theme.breakpoints.sm)} {
      font-size: ${theme.fontSizes["2xl"]};
    }

    padding-left: ${(props) => props.padding};
  }

  h3,
  h3 > * {
    font-family: ${theme.fonts.heading};
  }
`;

interface SubheadingProps {
  padding?: string;
  // Can be used to render Prismic RichText content
  innerHTML?: string;
}

export const Subheading: React.FC<SubheadingProps> = ({
  children,
  innerHTML,
  padding = theme.space[2]
}) => {
  const innerHTMLProp = innerHTML
    ? { dangerouslySetInnerHTML: { __html: innerHTML } }
    : {};

  return (
    <StyledPageTitle padding={padding}>
      <h3 {...innerHTMLProp}>{children}</h3>
    </StyledPageTitle>
  );
};
