import styled from "@emotion/styled";
import React from "react";
import theme from "../theme";

export interface TableProps {
  columns: string[];
  rows: { [key: string]: string }[];
  showColumnNames: boolean;
  // If true, sort on first column and omit duplicate first column entries:
  // i.e. for data:
  // adam   item1   10
  // bobby  item1   1
  // adam   item2   1
  //
  // Outcome would be:
  // adam   item1   10
  //        item2   1
  // bobby  item1   1
  groupSortColumn1: boolean;
  italiciseColumn?: boolean[];
}

const TableWrapper = styled.div`
  table {
    width: 95%;
    table-layout: fixed;
    border-spacing: 5px 15px;
  }

  th {
    text-align: left;
    font-family: ${theme.fonts.heading};
  }

  margin: ${theme.space[4]} 0;
`;

const Td = styled.td<{ italicise?: boolean }>`
  font-style: ${(props) => (props.italicise ? "italic" : "normal")};
`;

const sortTable = (
  column1Name: string,
  rows: { [key: string]: string }[],
  descending = false
) => {
  rows.sort((a, b) => {
    const aCol1 = a[column1Name];
    const bCol1 = b[column1Name];

    if (descending) {
      return aCol1 > bCol1 ? -1 : 1;
    } else {
      return aCol1 < bCol1 ? -1 : 1;
    }
  });
};

export const Table: React.FC<TableProps> = (props) => {
  const {
    showColumnNames,
    columns,
    rows: propRows,
    italiciseColumn,
    groupSortColumn1
  } = props;

  const rows = [...propRows];
  if (groupSortColumn1) {
    sortTable(columns[0], rows);
  }

  return (
    <TableWrapper>
      <table>
        {showColumnNames ? (
          <thead>
            <tr>
              {columns.map((col, idx) => (
                <th key={`table-head-${col}-${idx}`}>{col}</th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {rows.map((row, rowIdx) => {
            return (
              <tr key={`table-row-${rowIdx}`}>
                {columns.map((col, valIdx) => {
                  // If value is the same as the previous for first column, and
                  // `groupSortColumn1` is set, omit it
                  if (groupSortColumn1 && valIdx === 0) {
                    if ({ ...rows[rowIdx - 1] }[col] === row[col]) {
                      return <Td></Td>;
                    }
                  }
                  return (
                    <Td
                      key={`table-data-${rowIdx}-${valIdx}`}
                      italicise={italiciseColumn && italiciseColumn[valIdx]}
                    >
                      {row[col]}
                    </Td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </TableWrapper>
  );
};

export default Table;
