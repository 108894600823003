import { graphql, PageProps } from "gatsby";
import React from "react";
import Image from "../components/Image";
import Page from "../components/Page";
import { PageTitle } from "../components/PageTitle";
import SEO from "../components/SEO";
import { Subheading } from "../components/Subheading";
import Table from "../components/Table";
import {
  PrismicRepertoire,
  PrismicRepertoireBody2ColumnTable,
  PrismicRepertoireBodyTable
} from "../prismic-types";
import theme from "../theme";

type QueryData = { prismicRepertoire: PrismicRepertoire };

export const query = graphql`
  {
    prismicRepertoire {
      data {
        page_title {
          text
        }
        image_title {
          text
        }
        image_credit_link
        image_credit_name
        biography_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        body {
          ... on PrismicRepertoireBodyTable {
            id
            primary {
              column_1_name
              column_2_name
              column_3_name
              italicise_column_1
              italicise_column_2
              italicise_column_3
              table_name {
                text
              }
              show_column_names
              sort_group_by_column_1
            }
            items {
              column_1_value
              column_2_value
              column_3_value
            }
            slice_type
          }
          ... on PrismicRepertoireBody2ColumnTable {
            id
            items {
              column_1_value
              column_2_value
            }
            primary {
              column_1_name
              column_2_name
              italicise_column_1
              italicise_column_2
              show_column_names
              sort_group_by_column_1
              table_name {
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;

type TwoColumnData = Pick<PrismicRepertoireBodyTable, "primary" | "items">;
type ThreeColumnData = Pick<
  PrismicRepertoireBody2ColumnTable,
  "primary" | "items"
>;

interface RepertoireTable {
  type: "3column" | "2column";
  data: TwoColumnData | ThreeColumnData;
}

const is3Column = (
  table: TwoColumnData | ThreeColumnData
): table is ThreeColumnData => {
  // eslint-disable-next-line no-prototype-builtins
  return Object(table.primary).hasOwnProperty("column_3_name");
};

const tablesFromPrismicData = (data: QueryData) => {
  const tables: any[] = (data.prismicRepertoire.data?.body || []).map((t) => {
    const type = t?.slice_type === "2-column_table" ? "2column" : "3column";
    return { type, data: { primary: t?.primary, items: t?.items } };
  });

  return tables.map((tableData) => {
    const tableName = tableData.data?.primary?.table_name?.text ?? "";

    const columns: string[] = [
      tableData.data?.primary?.column_1_name ?? "__hidden1",
      tableData.data?.primary?.column_2_name ?? "__hidden2"
    ];

    if (is3Column(tableData.data)) {
      columns.push(tableData.data?.primary?.column_3_name ?? "__hidden3");
    }

    const showColumnNames = tableData.data?.primary?.show_column_names ?? false;
    const groupSortColumn1 =
      tableData.data?.primary?.sort_group_by_column_1 ?? false;

    const italiciseColumn = [
      tableData.data?.primary?.italicise_column_1 ?? false,
      tableData.data?.primary?.italicise_column_2 ?? false
    ];

    if (is3Column(tableData.data)) {
      italiciseColumn.push(
        tableData.data?.primary?.italicise_column_3 ?? false
      );
    }

    const rows: Record<string, string>[] = (tableData.data.items as any[]).map(
      (item) => {
        const out = {
          [columns[0]]: item.column_1_value,
          [columns[1]]: item.column_2_value
        };

        if (is3Column(tableData.data)) {
          out[columns[2]] = item.column_3_value;
        }
        return out;
      }
    );

    return {
      tableName,
      columns,
      rows,
      showColumnNames,
      groupSortColumn1,
      italiciseColumn
    };
  });
};

const RepertoirePage: React.FC<PageProps<QueryData>> = (props) => {
  const imageData =
    props.data?.prismicRepertoire?.data?.biography_image?.localFile
      ?.childImageSharp?.fluid;
  const imageTitle = props.data?.prismicRepertoire?.data?.image_title?.text;
  const imageCreditTitle =
    props.data?.prismicRepertoire?.data?.image_credit_name;
  const imageCreditLink =
    props.data?.prismicRepertoire?.data?.image_credit_link;

  if (!imageData || !imageTitle || !imageCreditTitle || !imageCreditLink) {
    throw new Error("Some of the data is not present, cannot load this page");
  }

  const repertoireTables = tablesFromPrismicData(props.data);
  return (
    <Page>
      <SEO title="Repertoire" />
      <PageTitle>
        {props.data?.prismicRepertoire?.data?.page_title?.text}
      </PageTitle>
      <Image
        title={imageTitle}
        credit={{ title: imageCreditTitle, link: imageCreditLink }}
        data={imageData}
      />
      {repertoireTables.map((table, idx) => {
        const { tableName, ...tableProps } = table;
        return (
          <div
            key={`repertoire-table-${idx}`}
            style={{ paddingTop: theme.space[4], marginLeft: theme.space[4] }}
          >
            <Subheading>{tableName}</Subheading>
            <Table {...tableProps} />
          </div>
        );
      })}
    </Page>
  );
};

export default RepertoirePage;
